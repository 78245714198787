* {
    box-sizing: border-box;
}

body {
    margin: 5px 5px 30px 5px;
    font-family: Verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: Verdana, sans-serif;
}
